<template>
  <div class="container">
      <!-- <div class="page-banner">
        <div id="myCarousel" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
            <li data-target="#myCarousel" data-slide-to="2"></li>
          </ol>

          <div class="carousel-inner">
            <div class="item active">
              <img src="b1.png" alt="" style="width:100%;">
            </div>
      
            <div class="item">
              <img src="b2.jpg" alt="" style="width:100%;">
            </div>
          
            <div class="item">
              <img src="b3.jpg" alt="" style="width:100%;">
            </div>
          </div>
      
          <a class="left carousel-control" href="#myCarousel" data-slide="prev">
            <span class="glyphicon glyphicon-chevron-left"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="right carousel-control" href="#myCarousel" data-slide="next">
            <span class="glyphicon glyphicon-chevron-right"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="right-banner">
          <div class="c1"><img src="c1.png" alt="" ></div>
          <div class="c1"><img src="c2.png" alt="" ></div>
          <div class="c1"><img src="c3.png" alt="" ></div>
        </div>
      </div> -->
      <div class="shop-collections">
        <div class="collections">
          Shop Quality Dry Food Items
        </div>
        <div class="row col-lg-12 col-sm-12  product-container">
            <div class="col-lg-2 col-sm-2" v-for="(data, index) in products" :key="index">
                <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                  <div class="product" >
                      <img class="img" :src="img_url + data.product_image" alt="">
                      <div class="product-name">
                          {{ data.product_name.split(' ').slice(0, 4).join(' ') + '...' }}
                      </div>
                      <div class="product-price">
                        ₦{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                      </div>
                  </div>   
                </router-link>
            </div>
        </div> 
      </div>
      <div class="shop-collections1">
        <div class="collections1">
          Shop Quality Dry Food Items
        </div>
        <div class="product-container-mobile">
          <div class="mobile-item" v-for="(data, index) in products" :key="index">
              <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                <div class="product-mobile" >
                    <img class="img" :src="img_url + data.product_image" alt="">
                    <div class="product-name">
                        {{ data.product_name.split(' ').slice(0, 4).join(' ') + '...' }}
                    </div>
                    <div class="product-price">
                      ₦{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                    </div>
                </div>   
              </router-link>
          </div>
        </div>  
      </div>
      <div class="best-deals" v-if="mobile_devices != ''">
        <div class="deals">
          Shop Quality Dry Food Items
        </div>
        <div class="product-container1">
          <div class="col-sm-2" v-for="(data, index) in mobile_devices" :key="index">
              <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                <div class="product1" >
                    <img class="img" :src="img_url + data.product_image" alt="">
                    <div class="product-name1">
                        {{ data.product_name.split(' ').slice(0, 3).join(' ') + '...' }}
                    </div>
                    <div class="product-price1">
                      ₦{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                    </div>
                </div>   
              </router-link>
          </div>
        </div> 
      </div>
      <div class="best-deals" v-if="computer_devices != ''">
        <div class="deals">
          Shop Quality Dry Food Items
        </div>
        <div class="product-container1">
          <div class="col-sm-2" v-for="(data, index) in computer_devices" :key="index">
              <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                <div class="product1" >
                    <img class="img" :src="img_url + data.product_image" alt="">
                    <div class="product-name1">
                        {{ data.product_name.split(' ').slice(0, 3).join(' ') + '...' }}
                    </div>
                    <div class="product-price1">
                      ₦{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                    </div>
                </div>   
              </router-link>
          </div>
        </div> 
      </div>
      <div class="best-deals" v-if="home_appliances != ''">
        <div class="deals">
          Shop Quality Dry Food Items
        </div>
        <div class="product-container1">
          <div class="col-sm-2" v-for="(data, index) in home_appliances" :key="index">
              <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                <div class="product1" >
                    <img class="img" :src="img_url + data.product_image" alt="">
                    <div class="product-name1">
                        {{ data.product_name.split(' ').slice(0, 3).join(' ') + '...' }}
                    </div>
                    <div class="product-price1">
                      ₦{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                    </div>
                </div>   
              </router-link>
          </div>
        </div> 
      </div>
      <!-- <div class="best-deals">
        <div class="deals">
          Art Gallery
        </div>
        <div class="product-container1">
          <div class="col-sm-2" v-for="(data, index) in products" :key="index">
              <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                <div class="product1" >
                    <img class="img" :src="img_url + data.product_image" alt="">
                    <div class="product-name1">
                        {{ data.product_name.split(' ').slice(0, 3).join(' ') + '...' }}
                    </div>
                    <div class="product-price1">
                      ₦{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                    </div>
                </div>   
              </router-link>
          </div>
        </div> 
      </div> -->
      
      <page-loader :is-loading="isLoading"></page-loader>
  </div>
</template>
<script>

//import 'quill/dist/quill.snow.css';
//import { quillEditor } from 'vue-quill-editor';
import PageLoader from '../components/ShopLoader.vue'
import swal from 'sweetalert2';
export default {
  
  name: "ShoppingPage",
  components: {
      PageLoader,
  },

  props: {
        products: Array,
        isLoading: Boolean
  },

  data(){
    return {

        isLoading2: false,
        isLoading3: false,
        isLoading4: false,
        user_id: '',
        firstname: '',
        lastname: '',
        bank_name:'',
        account_name:'',
        account_number: '',
        
        loading: false,
             
        shops: [],
        brands: [],
        categorys: [],
        subcategorys:[],
        img_url: process.env.VUE_APP_IMAGE_URL+'products/',
        cart_id: '',
        mobile_devices: [],
        computer_devices: [],
        home_appliances: [],
        
    };
  },

  beforeMount(){
   
  },

  mounted(){

      this.firstname = localStorage.getItem("firstname");
      this.lastname = localStorage.getItem("lastname");
      this.phone_number = localStorage.getItem("phone");
      this.email_address = localStorage.getItem("email");
      this.user_id = localStorage.getItem("userid");
      this.cart_id = localStorage.getItem('cart_id');

      this.bank_name = localStorage.getItem("bank_name");
      this.account_name = localStorage.getItem("account_name");
      this.account_number = localStorage.getItem("account_number");
      this.fetchcategorys()
      this.fetchshops()
      this.fetchbrands()
      this.fetchHomeAppliances()
      this.fetchMobileDevices()
      this.fetchComputerDevices()

      if (!this.cart_id) {
        this.cart_id = this.generateRandomId();
        localStorage.setItem('cart_id', this.cart_id);
      }
  },

  created(){
        
  },

  computed: {
  
  },

  methods: {

     generateRandomId() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
     },

      setShowBtn(index, value) {
        this.$set(this.showBtn, index, value);
      },

      async fetchcategorys() {
          try {
             const response =  await this.$http.get('/fetch-all-categories', {
          })

          this.categorys = response.data.categories;
                                 
          } catch (err) {
            console.log(err);
        }
      },

      async fetchshops() {
          try {
             const response =  await this.$http.get('/fetch-all-shops', {
          })
              response.data.shops.forEach(el => {
                  this.shops.push({
                      text: el.shop_name,
                      value: el.shopid
                  })
              });
                  
          } catch (err) {
            console.log(err);
        }
      },

      async fetchbrands() {
          try {
             const response =  await this.$http.get('/get-brands', {
          })
          this.brands = response.data.brands              
          } catch (err) {
            console.log(err);
        }
      },

      async fetchProducts() {
          try {
             this.isLoading = true
             const response =  await this.$http.get('/fetch-all-products', {
          })
              this.isLoading = false
              this.products = response.data.products
                  
          } catch (err) {
            console.log(err);
        }
      },

      // fetch mobile devices
      async fetchMobileDevices() {
          try {
             this.isLoading2 = true
             const response =  await this.$http.get('/fetch-all-mobile-devices', {
          })
              this.isLoading2 = false
              this.mobile_devices = response.data.products

              console.log(this.mobile_devices )
                  
          } catch (err) {
            console.log(err);
        }
      },

      // fetch computer devices
      async fetchComputerDevices() {
          try {
             this.isLoading3 = true
             const response =  await this.$http.get('/fetch-all-computer-devices', {
          })
              this.isLoading3 = false
              this.computer_devices = response.data.products
                  
          } catch (err) {
            console.log(err);
        }
      },

      // fetch home appliances
      async fetchHomeAppliances() {
          try {
             this.isLoading4 = true
             const response =  await this.$http.get('/fetch-all-home-appliances', {
          })
              this.isLoading4 = false
              this.home_appliances = response.data.products
                  
          } catch (err) {
            console.log(err);
        }
      },

      async removeCategory(categoryid){
      let con = confirm("          You're about to remove this category. Are you sure?")
        if (con == true) {
          try {

            const response =  await this.$http.post('/delete-subcategory', {
              subcategory_id: categoryid,
            })

            if( !response.data.success ){
                  swal.fire({
                      icon: 'error',
                      title: 'Error',
                      width: 450,
                      height: 5,
                      text: response.data.message,
                  })
                  return;
              }

              swal.fire({
                  icon: 'success',
                  title: 'Success',
                  width: 450,
                  height: 5,
                  text: response.data.message,
              })
              this.fetchProducts()
              return;

            } catch (err) {
            console.log(err);
            }
        }
      },

  }

}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

.container-fluid{

}
.page-banner{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  height: 400px;
}

.right-banner{
  display: flex;
  flex-direction: column;
  margin: 4px;
}
.c1{
  margin: 2px;

  img{
    width:450px;
    height: 115px;
  }
}
.inputs{
margin-top: 20px;
border-radius: 3px;
font-size: 12px;
}
.shop-collections1{
  display: none;
}
.collections1{
  display: none;
}
.shop-collections{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}
.collections{
  display: flex;
  background: #8000808f;
  height: 50px;
  padding: 0.8rem;
  font-size: 18px;
  font-family: Mulish;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
  color: #fff;
  
}
.product-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: #fff;
  margin-bottom: 30px;
  
  a{
      text-decoration: none;
      color: #000;
  }
}
.best-deals{
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
}
.deals{
  background: #8000808f;
  height: 50px;
  padding: 0.8rem;
  font-size: 18px;
  font-family: Mulish;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #fff;
}
.product-container1{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem;
  background: #fff;
  margin-bottom: 30px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow-x: auto;
  
  a{
      text-decoration: none;
      color: #000;
  }
}
.product-container-mobile{
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  background: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 4px;
  
  a{
      text-decoration: none;
      color: #000;
  }
}

.product{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 205px;
  padding: 0.3rem;
  font-family: Mulish;
  border: solid 1px #ccc;
  border-radius: 4px;
  margin-bottom: 4px;

  img{
    width: 100%;
    height: 145px;
  }
  .product-name{
      //margin-top: 5px;
      padding: 0.2rem;
      font-size: 10px;
      font-family: Mulish;
      text-align: left;
    }
    .product-price{
      padding: 0.2rem;
      font-size: 10px;
      font-weight: bold;
      font-family: Mulish;
      margin-top: -1px;
      text-align: left;
    }
}
.product:hover{
  border: solid 1px #80008070;
  box-shadow: 1px 1px 10px rgba(14, 112, 31, 0.2); 
}

.product1{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 205px;
  padding: 0.3rem;
  font-family: Mulish;
  border: solid 1px #ccc;
  border-radius: 4px;
  margin-bottom: 4px;
  
  .product-name1{
      margin-top: 2px;
      padding: 0.2rem;
      font-size: 10px;
      font-family: Mulish;
      text-align: center;
    }
    .product-price1{
      padding: 0.2rem;
      font-size: 10px;
      font-weight: bold;
      font-family: Mulish;
      margin-top: -2px;
      text-align: center;
    }
}
.product1:hover{
  border: solid 1px #80008070;
  box-shadow: 1px 1px 10px rgba(14, 112, 31, 0.2); 
}

.product-mobile{
  display: none;
  flex-direction: column;
  width: 100%;
  height: 210px;
  padding: 0.3rem;
  font-family: Mulish;
  border: solid 1px #ccc;
  border-radius: 4px;
  margin-bottom: 4px;
  .product-name{
      margin-top: 10px;
      padding: 0.2rem;
      font-size: 12px;
      font-family: Mulish;
      text-align: center;
    }
    .product-price{
      padding: 0.2rem;
      font-size: 13px;
      font-weight: bold;
      font-family: Mulish;
      margin-top: -5px;
      text-align: center;
    }
}
.product-mobile:hover{
  border: solid 1px #80008070;
  box-shadow: 1px 1px 10px rgba(14, 112, 31, 0.2); 
}
.button-cart{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.add-cart{
  height: 40px;
  background: #071278;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  bottom: 0;
  position: absolute;
  width: 84%;

}

.img {
  cursor: pointer;  
  height:145%;
  border-radius: 4px;
}

.inputs-field{
  font-size: 14px;
  font-family: Mulish;
  height: 45px;
}
@media screen and (max-width: 768px) {
  .page-banner{
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
  }
  .right-banner{
    display: flex;
    flex-direction: column;
    margin: 4px;
  }
  .shop-collections{
   display: none
    
  }
  .collections{
    display: none;
  }
  .shop-collections1{
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    margin-top: 5px;
  }
  .collections1{
    display: flex;
    background: #8000808f;
    height: 50px;
    padding: 0.4rem;
    font-size: 18px;
    font-family: Mulish;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
    color: #fff;
    
  }
  .c1{
    margin: 2px;
  
    img{
      width:330px;
    }
  }
  .product-container{
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .best-deals{
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin-left: 0px;
      margin-right: 0px;
    }
    .product-container1{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.4rem;
      background: #fff;
      margin-bottom: 30px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow-x: auto;
      
      a{
          text-decoration: none;
          color: #000;
      }
    }

    .product-container-mobile{
      display: flex;
      flex-wrap:wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.1rem;
      background: #fff;
      margin-top: 0px;
      margin-bottom: 30px;
      border-radius: 4px;

      a{
          text-decoration: none;
          color: #000;
      }
    }
    .mobile-item{
      width: 50%;
      padding: 2px;
      box-sizing: border-box;
    }

    .product{
      display: none;
      flex-direction: column;
      align-items: center;
      padding: 0.8rem;
      font-family: Mulish;
    }
    .product1{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 210px;
      padding: 0.3rem;
      font-family: Mulish;
      border: solid 1px #ccc;
      border-radius: 4px;
      margin-bottom: 4px;

      .img {
        cursor: pointer;  
        height:100%;
        width: 150px;
      }
      .product-name1{
          margin-top: 2px;
          padding: 0.2rem;
          font-size: 10px;
          font-family: Mulish;
          text-align: center;
        }
        .product-price1{
          padding: 0.2rem;
          font-size: 10px;
          font-weight: bold;
          font-family: Mulish;
          margin-top: -5px;
          text-align: center;
        }
    }
    .product1:hover{
      border: solid 1px #80008070;
      box-shadow: 1px 1px 10px rgba(14, 112, 31, 0.2); 
    }

    .product-mobile{
      display: flex;
      flex-direction: column;
      align-items: left !important;
      padding: 0.2rem;
      font-family: Mulish;

      .product-name{
        padding: 0.2rem;
        font-size: 10px;
        font-family: Mulish;
        text-align: left;
      }
      .product-price{
        padding: 0.2rem;
        font-size: 10px;
        font-weight: bold;
        font-family: Mulish;
        margin-top: -3px;
        text-align: left;
      }
    }

    .img {
      cursor: pointer;  
      height:100%;
      width: 100%;
    }
  
  .inputs{
    font-size: 12px;
  }


}

</style>