<template>
<div class="background">
    <div class="container1">
        <div class="col-lg-12 mt-0 p-4 header">
            <div class="logo"><img src="logo1.png" alt="Logo"></div>
            <div class="login"><i class="fa fa-headphones" aria-hidden="true"></i> 24hr support</div>
        </div>
        <div class="col-lg-12 login-container">
            <div class="col-lg-8 col-sm-8 app-title">
               <h2>Sign In</h2> 
               <p>Buy & Pay Small Small</p>
            </div>
            <div class="col-lg-4 col-sm-4 login-form">
                <p class="text-uppercase login-color p-login">Login</p>
                <div class="login-form-inner">
                    <div class="inputs"> 
                        <label for="">Username *</label>
                        <input type="email" class="form-control inputs-field" placeholder="Enter username" v-model="username">
                        <label for="">Password *</label>
                        <input type="password" class="form-control inputs-field" placeholder="Enter password" v-model="password">
                    </div>
                    <div class="inputs">
                        <p class="forgot-password"> <router-link to="/forgot-password">Forgot your password?</router-link></p>
                    </div>
                    <div class="inputs">
                        <button id="submit4" class="btn btn-danger btn-block" :disabled="isSubmitButtonDisabled" @click="login()" v-if="!loading">Login</button>
                        <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div class="footer">
                    <div class="copyright">
                        <p>Don't have an account? <span><router-link to="/register">Create account</router-link></span><br><a href="https://dailychamp.ng">Daily Champ Website</a> | <a href="/shopping">Shop</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Vue from 'vue'
import VueCountryCode from "vue-country-code";
import swal from 'sweetalert2';

Vue.use(VueCountryCode);

export default {
    
    name: 'SigninPage',
    components: {

    },
    
    data() {
    return {
      appTitle: "Daily Champ",
     
      submit_4: false,
      username: "",
      password: "",
      loading: false,
    };
  },

  beforeMount(){
    document.title = this.appTitle
  },
  mounted() {
   
  },
  created(){
    
  },
  computed: {
    isSubmitButtonDisabled() {
    return (
      this.username === '' ||
      this.password === ''
    );
  }
  },

  methods:{

    async login() {
            try {

                this.loading = true

               const response =  await this.$http.post('/login', {
                  username: this.username,
                  password: this.password
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                let token = response.data.token;
                await localStorage.setItem("wertyuiojhdfghhdrtfyguh", token)
                await localStorage.setItem("firstname", response.data.profile[0].firstname)
                await localStorage.setItem("lastname", response.data.profile[0].lastname)
                await localStorage.setItem("email", response.data.profile[0].email_address)
                await localStorage.setItem("bank_name", response.data.profile[0].bank_name)
                await localStorage.setItem("account_name", response.data.profile[0].account_name)
                await localStorage.setItem("account_number", response.data.profile[0].account_number)
                await localStorage.setItem("phone", response.data.profile[0].phone_number)
                await localStorage.setItem("userid", response.data.profile[0].userid)
                await localStorage.setItem("user_role", response.data.profile[0].user_role)
                await localStorage.setItem("current_plan", response.data.profile[0].current_plan)
                await localStorage.setItem("plan_active_status", response.data.profile[0].plan_active_status)
                await localStorage.setItem("referral_code", response.data.profile[0].referral_code)
                await localStorage.setItem("state", response.data.profile[0].state)
                await localStorage.setItem("city", response.data.profile[0].city)
                await localStorage.setItem("street", response.data.profile[0].street)

                this.$router.push('/shopping')
               
            } catch (err) {
              console.log(err);
        }
    },
    
  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    .background{
        min-height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: none;
        background-image: linear-gradient(to right,#cccccc8a, #80008009, #cccccc8a);
    }
  
    .container1{
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        width: 100%;
    }
    .login-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        text-transform:uppercase;
        font-size: 12px;
        font-weight: 700;
        font-family: Mulish;
    
        .logo{
            img{
                height: 50px;
                width: auto;
            }
        }

        .login{
            font-family: Mulish;
            color: #000000d4;
        }
    }
    .get-started{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 20px;
        font-family: Mulish;
        font-size: 25px;
        font-weight: 600;
        word-wrap: break-word;
        text-align: center;
        color: #fff;
    }
    .app-title{
        h2{
            font-family: Mulish;
            font-size: 40px;
            font-weight: bold;
            text-align: center;
            color: white;
            text-shadow: 2px 2px 4px #800080cf;
            text-transform: uppercase;
        }
        p{
            font-family: Mulish;
            font-size: 18px;
            text-align: center;
            color: white;
            text-shadow: 2px 2px 4px #000000;
            text-transform: capitalize;
        }
    }
    .login-form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .login-color{
        color: #800080;
    }

    .forgot-password{
        a {
            color: #800080;
            text-decoration: none;
        }
    }
    .login-form-inner{
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        padding: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Mulish;
        font-size: 13px;
        font-weight: 500;
        color: #000 !important;
        height: auto;
        width: 100%;
    }

    .counter{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        padding: 4px;
        height: 30px;
        border-radius: 3px;

        span{
            color: #000;
        }
        .step-title{
            font-weight: 700;
        }
    }
    .inputs{
        margin-top: 20px;
        border-radius: 3px;
    }
    .inputs-field{
        font-size: 14px;
        font-family: Mulish;
        height: 50px;
        border: #800080 solid 1px;
    }
    .btn-danger{
        text-transform: uppercase;
        font-size: 13px;
        font-family: Mulish;
        background: #800080;
        border: 0px;
        height: 45px;
    }
    .footer{
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }
    .copyright{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: Mulish;
        font-size: 13px;
        text-align: center;
        color: #000000d4;

        span{
            a {
                color: #800080;
                text-decoration: none;
                font-weight: bold;
                font-size: 14px;
            }
        }
        a {
            text-decoration: none;
            font-size: 10px;
        }
    }
    @media screen and (max-width: 768px) {
        .background{
            font-family: Mulish !important;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-bottom: 0px;
            background-image: linear-gradient(to right,#cccccc8a, #cccccc8a, #cccccc8a);
        }
        .container{
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            width: 100%;
           
            .logo{
                img{
                    height: 50px;
                    width: auto;
                }
            }
            .login{
                color: #000000d4;
            }
        }
        .login-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .app-title{
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            padding: 0rem;
            text-align: center;
           
            h2{
                font-size: 25px;
            }
            p{
                display: none;
            }
           
        }
        .login-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .p-login{
                display: none;
            }
        }
        .login-form-inner{
            width: 100%;
        }

        .get-started{
            font-size: 20px;
        }


    }
</style>