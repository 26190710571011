<template>
    <div id="top" style="background: #fff">
        <div class="fixed-top">
            <div id="top-header" class="top-header">
                <div class="haddress">
                <span><i class="fa fa-map-marker" aria-hidden="true"></i> Model Plaza, Aso Road, Mararaba</span>
                <span><i class="fa fa-phone"></i> 0706 9500 520, 0810 636 5785</span>
                <span><i class="fa fa-envelope"></i> <a href="mailto:support@dailychamp.ng">support@dailychamp.ng</a> </span>
                </div>
                <div class="htime" >
                    <span></span>
                    <span><i class="fa fa-clock" aria-hidden="true"></i> Office Hours: 08:00am - 05:00pm</span>
                    <span></span>
                </div>
                <div class="hsocial">
                <span><i class="fa fa-headphones" aria-hidden="true"></i> 24hr support</span>
                
                </div>
            </div>
            <div class="col-12 d-flex flex-row justify-space-between align-center">
                <div class="col-0"></div>
                <div class="col-2 logo">
                    <router-link to="/"> <img src="logo1.png" alt="Logo"> </router-link>
                </div>
                <div class="col-8 col-lg-6 d-flex flex-row justify-space-around align-center menu-bar">
                    <div class="menu">
                        <a href="#home">Home</a> 
                    </div>
                    <div class="menu">
                        <a href="#core-values">Core Values</a> 
                    </div>
                    <div class="menu">
                        <a href="#howto">How To</a> 
                    </div>
                    <div class="menu">
                        <a href="#aboutus">About Us</a> 
                    </div>
                    <div class="menu">
                        <a href="#faq">FAQ</a> 
                    </div>
                    <div class="menu">
                        <a href="#contact">Contact Us</a> 
                    </div>
                </div>
                <div class="col-2 col-lg-2">
                    <div class="menu-bar1">
                        <span><i class="fa fa-bars" aria-hidden="true"></i></span>
                        <Transition>
                            <div class="submenu-bar1">
                                <a href="#home" class="sub-menu1">Home</a> 
                                <a href="#core-values" class="sub-menu1">Core Values</a> 
                                <a href="#howto" class="sub-menu1">How To</a> 
                                <a href="#aboutus" class="sub-menu1">About Us</a>
                                <a href="#faq" class="sub-menu1">FAQ</a>  
                                <a href="#contactus" class="sub-menu1" >Contact Us</a>  
                            </div>
                        </Transition>
                    </div>
                </div>
                <div class="col-0"></div>
            </div>
        </div>
        <div id="home" class="col-lg-12 col-sm-12 banner">
            <div class="overlay"></div>
            <div class="col-0"></div>
            <div class="col-lg-8 col-sm-8 btn-style">
                <h1>Premium Food Staples at the Best Prices!</h1>
                <h4>Shop Beans, Rice, Onions, Stockfish & More - Fresh & Affordable</h4>
                <router-link id="core-values" to="/shopping" target="_blank">
                    <button class="btn btn-success mt-4">SHOP NOW <i class="fa fa-shopping-cart"></i></button>
                </router-link>
            </div>


            <div class="col-lg-4 col-sm-4 img-style">
                <img  src="../assets/2.png" alt="">
                <!-- <img  src="../assets/2.png" alt="" v-show="showImage === 1"> -->
                <!-- <img src="../assets/paystack.png" alt="" v-show="showImage === 2"> -->
                <!-- <img src="../assets/mob.png" alt="" v-show="showImage === 3"> -->
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 pa-5 core-values">
            <div class="col-lg-3 col-sm-3 guarantee">      
                <h3><i class="fa fa-cart-plus"></i> <br>Buy Now</h3> 
                <span>Get what you need today <br>and pay with ease</span>
            </div>
            <div class="col-lg-3 col-sm-3 guarantee">      
                <h3><i class="fa fa-check"></i> <br>Top-Notch Quality</h3> 
                <span>Only the best, freshest, and most <br>trusted items for you</span>
            </div>
            <div class="col-lg-3 col-sm-3 guarantee">
                <h3><i class="fa fa-truck"></i>  <br>Swift & Trusted Delivery</h3>
                <span>Receive your orders on time, <br>once payment is confirmed</span>
            </div>
            <div class="col-lg-3 col-sm-3 guarantee"> 
                <h3> <i class="fa fa-lock"></i>  <br>Safe & Secure Transactions</h3>
                <span>Shop with confidence - <br>your payments are always protected</span>
            </div>
        </div>

        <div id="howto" class="col-lg-12 pt-3 bg-style">
            <div class="overlay1"></div>
            <div class="col-lg-4 col-sm-4 pt-0 pa-2">
                <img src="../assets/1.png" alt="">
            </div>
            <div class="col-lg-8 col-sm-8 platform-1">
                <div class="platform">
                    <h2>Shop Fresh & Quality Food Items with Ease!</h2>
                    <span class="select-item"> Explore our wide range of food staples.</span>
                    <span class="select-item"> Add rice, beans, stockfish, onions, and more to your cart.</span>
                    <span class="select-item"> Place your order effortlessly.</span>
                    <span class="select-item"> Choose your preferred delivery option.</span>
                    <span class="select-item"> Make your payment securely.</span>
                    <span class="select-item"> Get fast and reliable delivery to your doorstep.</span>
                    <span class="select-item"> Enjoy fresh, quality food items anytime!</span>

                    <div class=" pt-0 mt-0">
                        <router-link to="/shopping" target="_blank"> <button class="btn btn-success mt-4">SHOP NOW <i class="fa fa-shopping-cart"></i></button></router-link>
                    </div>
                </div>
            </div>
        </div>        
        <div id="aboutus" class="col-lg-12 about">
           <div class="overlay2"></div>
          
           <div class="col-lg-8 border">
                <h4>About Us</h4>
                <p>Daily Champ is your trusted marketplace for quality food staples. We connect buyers with top suppliers, ensuring access to fresh and affordable essentials like rice, beans, stockfish, onions, and more. Our goal is to make food shopping convenient, reliable, and budget-friendly.</p>
                
                <h4>Our Vision</h4>
                <p>To be the leading platform for fresh and affordable food items, ensuring easy access for every household.</p>
                
                <h4>Our Mission</h4>
                <p>To provide quality food staples with fast and reliable delivery while expanding our reach across Africa.</p>
            </div>
         
        </div>
        <div id="faq" class="col-lg-12 col-sm-12 bg-task">
            <div class="col-lg-1 col-sm-1"></div>
            <div class="col-lg-8 col-sm-8">
                <div class="d-flex flex-column pt-0 mt-0">
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseOne">
                                    <div><span class="badge badge-secondary">1</span>&nbsp;How do I start?</div>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    <ul>
                                        <li>Browse through our selection of food items.</li>
                                        <li>Add your desired items to your cart.</li>
                                        <li>Proceed to checkout and make payment.</li>
                                        <li>Provide your delivery details.</li>
                                        <li>Receive your order at your doorstep.</li>
                                    </ul> 
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                    <div><span class="badge badge-secondary">2</span>&nbsp;How do I make payment?</div>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    You can make your payment via the following payment channels:
                                    <ul>
                                        <li>Bank Transfer</li>
                                        <li>USSD</li>
                                        <li>ATM Card</li>
                                    </ul> 
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                    <div><span class="badge badge-secondary">3</span>&nbsp;When will I receive my food items?</div>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Delivery is processed within 24-72 hours, depending on your location.
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseFour">
                                    <div><span class="badge badge-secondary">4</span>&nbsp;What if an item is out of stock?</div>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    If an item is out of stock, we will notify you immediately and provide an option to replace or refund the item.
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseFive">
                                    <div><span class="badge badge-secondary">5</span>&nbsp;Can I cancel my order?</div>
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Yes, you can cancel your order before it is dispatched. A small cancellation fee may apply.
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseSix">
                                    <div><span class="badge badge-secondary">6</span>&nbsp;Do you offer refunds?</div>
                                </a>
                            </div>
                            <div id="collapseSix" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Yes, refunds are processed for orders canceled before dispatch or in case of incorrect items delivered.
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseSeven">
                                    <div><span class="badge badge-secondary">7</span>&nbsp;How do I track my order?</div>
                                </a>
                            </div>
                            <div id="collapseSeven" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Once your order is shipped, you will receive a tracking number via SMS or email.
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseNine">
                                    <div><span class="badge badge-secondary">8</span>&nbsp;What is the process for receiving my food items?</div>
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Once your order is confirmed, we:
                                    <ul>
                                        <li>Confirm your delivery address</li>
                                        <li>Dispatch your order via our logistics partner</li>
                                    </ul> 
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseTen">
                                    <div><span class="badge badge-secondary">9</span>&nbsp;Is delivery free?</div>
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    No, delivery fees vary based on your location and the size of your order.
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="col-lg-3 col-sm-3">
                <img src="../assets/faq.svg" alt="">
            </div>
        </div>

        <div id="contact" class="col-lg-12 contact">
            <div class="col-lg-4 bd">
                <h4 class="support-text">Our dedicated support team is always here to help you out.</h4>
                <img src="../assets/contact.png" alt="">
           </div>
            <div class="col-lg-4 contact-banner bd">
                <h4>Contact Us</h4>
                 <label>Fullname *</label>
                 <input class="form-control" type="text" name="" v-model="fullname" id="" placeholder="Enter your fullname">
                 <label>Email *</label>
                 <input class="form-control" type="email" name="" v-model="email_address" id="" placeholder="Enter email address">
                 <label>Phone *</label>
                 <input class="form-control" type="text" name="" v-model="phone_number" id="" placeholder="Enter phone number">
                 <label>Mesasge *</label>
                 <textarea class="form-control" name="" v-model="message" id="" cols="3" rows="4" placeholder="Tell us how we can help you"></textarea>
                 <br>
                 <button id="submit4" class="btn btn-secondary btn-sm input-contact" :disabled="isSubmitButtonDisabled" @click="submit_form()" v-if="!loading">Send message</button>
                 <button class="btn btn-secondary btn-sm input-contact" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 </button>
                
            </div>
            <div class="col-lg-4 bd">
                 <h4><i class="fa fa-envelope" aria-hidden="true"></i> Email Us</h4>
                 <p>Our dedicated team will help you out<br><span class="text-secondary"><a href="mailto:support@dailychamp.ng">support@dailychamp.ng</a></span></p>
                 <h4><i class="fa fa-map-marker" aria-hidden="true"></i> Our Office</h4>
                 <p>Visit us today at our office headquarter<br> <span class="text-secondary">Model Plaza, Aso Road, Mararaba</span></p>
                 <h4><i class="fa fa-headphones" aria-hidden="true"></i> Help Desk</h4>
                 <p>You can reach out via call or sms 24/7.<br> <span class="text-secondary">0706 9500 520, 0810 636 5785</span></p>
            </div>
            
        </div>
        <div class="content-footer1">
            <div class="content-footer" id="contactus">
                <div class="contact-info footer-img">
                    <img src="logo.png" alt="">
                    <p>"We guarantee fast, reliable and <br>secured shopping experience"</p>
                </div>
                <div class="contact-info">
                    <p class="contact-title">contact info</p>
                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Model Plaza, Aso Road, Mararaba</p>
                    <p><i class="fa fa-phone"></i> 0706 9500 520, 0810 636 5785</p>
                    <p><i class="fa fa-envelope"></i> <a href="mailto:support@dailychamp.ng">support@dailychamp.ng</a></p>
                </div>
                <div class="contact-info">
                    <p class="services-title">Useful Links</p>
                    <p><a href="https://vendor.dailychamp.ng/login" target="_blank">Vendor Hub</a> </p>
                    <p><router-link to="/terms-and-conditions#top">Terms & Conditions</router-link></p>
                    <p><router-link to="/privacy-policy#top">Privacy Policy</router-link></p>
                </div>
                <div class="contact-info">
                    <p class="services-title">Socials</p>
                    <p> <span><a href="https://www.facebook.com/devamplify/" target="_blank"><i class="fa fa-facebook-square"></i> Facebook</a></span> </p>
                    <p> <span><i class="fa fa-twitter-square"></i> Twitter </span></p>
                    <p> <span><i class="fa fa-instagram"></i> Instagram</span></p>
                </div>
            </div>
            <div>
                <p class="copyright">&copy;{{ new Date().getFullYear() }} {{company_name }}. All rights reserved. </p>
            </div>
        </div>  
        <!-- <div class="contact-support">
          <a href="https://wa.me/2347069500520" target="_blank"><img src="../assets/support.png" alt=""></a> 
         
        </div>   -->
        <a href="#top">  
        <div class="top">
            <i class="fa fa-arrow-up"></i>
        </div>  
        </a>   
</div>
</template>
<script>
import swal from 'sweetalert2';
import $ from 'jquery';

export default {
    
    data() {
    return {
        logo_url: process.env.VUE_APP_API_LOGO,
        company_phone: process.env.VUE_APP_COMPANY_PHONE,
        company_email: process.env.VUE_APP_COMPANY_EMAIL,
        company_name: process.env.VUE_APP_COMPANY,
        loading: false,
        fullname: '',
        email_address: '',
        phone_number: '',
        message: '',
        show: false,
        title: 'Daily Champ is a marketplace that connects buyers and sellers of quality food items. We offer fresh and packaged food products at the best prices in Nigeria. Order now and enjoy fast delivery!',
        showImage: 1,
        exists: false,
        items: [
          {
            src: process.env.VUE_APP_API_BANNER,
          },
          {
            src: process.env.VUE_APP_API_BANNER2,
          },       
        ],
        showTop: true,
        lastScrollTop: 0
    };
  },

  mounted() {

    $(document).ready(function() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.top').fadeIn();
           document.getElementById('top-header').style.display = 'none'
          
        } else {
            $('.top').fadeOut();
            if ($(this).scrollTop() == 0) {
                document.getElementById('top-header').style.display = 'flex'
            }
        }
       
    });

    $('.top').click(function() {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
        });
    });

    setInterval(() => {
        this.showImage = this.showImage === 2 ? 1 : this.showImage + 1;
    }, 10000); // Toggle images every 3 seconds

    document.title = this.title
   
  },
  beforeDestroy() {
   
  },
    created(){

    },
    computed: {
    isSubmitButtonDisabled() {
        return (
        this.fullname === '' ||
        this.email_address === '' ||
        this.phone_number === '' ||
        this.message === '' 
        );
    }
  },

  methods: {
   
    async submit_form() {
            try {

                this.loading = true

                const response = await this.$http.post('/send-message', {
                  fullname: this.fullname,
                  email_address: this.email_address,
                  phone_number: this.phone_number,
                  message: this.message
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })

                this.fullname = ''
                this.email_address = ''
                this.phone_number = ''
                this.message = ''

                return;
               
            } catch (err) {
              console.log(err);
        }
    },
  },
    
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
 .v-enter-from {
    opacity: 0;
    translate: -100px 0;
  }
  .v-enter-to {
    opacity: 1;
    translate: 0 0;
  }
  .v-leave-from {
    opacity: 1;
    translate: 0 0;
  }
  .v-leave-to {
    opacity: 0;
    translate: 100px 0;
  }
 
.top-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    padding-left: 5%;
    padding-right: 5%;
    color: #000000d6;
    background: #8000802b;
    box-shadow: 0 2px 4px -3px rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 13px;
}
.haddress{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
}
.htime{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
}
.hsocial{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span{
        margin: 10px;
    }

    a{
        color: #000;
    }
}
.core-values{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3{
        font-family: Mulish;
        font-size: 20px;
        font-weight: 750;
        color: #800080;
        text-align: center;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 200;
        text-align: center;
    }
}
.guarantee{
    display: flex;
    flex-direction: column;
    border: solid 0px #ccc;
    padding: 0.8rem;
}
.btn-success{
    background: #800080;
    border: solid 0;
    color: #FFF;
    height: 50px;
}
.btn-success:hover{
    background: #800080ae;
    border: solid 1px #fff;
    color: #FFF;
    height: 50px;
}
.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 1.1s;
  }
  .modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
    opacity: 0.1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
.header {
    background: #F2F2F2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
    padding-left: 5%;
    padding-right: 5%;
}
.sidebar{
    display: none;
}
.fa-bars {
    display: none;
}
.menu-bar {
    height:70px;
}

.menu-bar1{
    display:none;
 }
 .submenu-bar1{
    display:none;

    .submenu-bar{
        display: flex;
        flex-direction: column;
        left: 0;
        font-family: Mulish;
        font-size: 13px;
        border-right: solid 1px rgba(0, 0, 0, 0.3);;
        bottom: 0;
        height: 100vh;
    
        a{
            text-decoration: none;
            color: #312F33;
        }
    }
 }

.logo{
    img{ width: 30%; }
}

.menu > a {
    color: #800080e6;
    font-weight: 800;
    font-family: Mulish;
}
.menu:hover {
    cursor: pointer;
}
.fixed-top{
    z-index: 9999;
    background: #fff;
}
.banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 9.5rem;
    bottom: 0;
    font-family: Mulish;
    font-size: 18px;
    overflow: hidden;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg.jpg) no-repeat fixed center;
    background-size: cover;
      
    h1{
        font-family: Mulish;
        font-weight: 700;
        font-size: 60px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1px #000; 
    }
    h4{
        font-family: Mulish;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1px #000; 
    }
    .btn-style{
        font-family: Mulish;
        text-align: center;
    }
    .img-style{
        img{
            //width: 100%;
        }
    }
}  
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #800080e6; /* Change the color and opacity here */
  }
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(-90deg, #80008014, #80008055);
  }
.bg-style{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg1.jpg) no-repeat fixed center;
    background-size: cover;
    color: #fff;

    img{
        width: 110%;
    }
    .platform{
        display:flex;
        flex-direction: column;
        padding: 2.6rem;

    h2{
        font-size: 30px; 
        font-weight: 700;
        font-family: Mulish;
    }
    span{
        font-size: 14px; 
        font-weight: 350;
        font-family: Mulish;
    }
}
}

.card{
    border: solid 0px;
    border-bottom: solid 1px #fff;
    background: #8000808f;
}
.card-link{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 17px; 
    font-weight: 600;
    font-family: Mulish;
    height: 20px;
    color: #000;
   
}
.card-header{
    border-bottom: solid 1px #fff;
}
.card-body{
    font-size: 14px; 
    font-family: Mulish;
    color: #fff;
}
.badge-secondary{
    background: #800080a0;
}

.bg-task{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.2rem;
    height: auto;
    background-image: linear-gradient(-90deg, #80008014, #80008055);

    h2{
        font-size: 40px; 
        font-weight: 600;
        font-family: Mulish;

    }
    img{
        width: 90%;
    }
}
.select-item{
    border-left: solid 6px #8000808a;
    margin: 4px;
    padding: 0.2rem;
    font-size: 16px !important; 
    font-weight: bold !important;
    font-family: Mulish;
}
.slider {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 500px; 
    font-size: 45px; 
    font-weight: bold;
    text-shadow: 0px 1px #000; 
    font-family: Mulish;
    text-align: center;
  }
.sample-product-subtitle{
    font-size: 20px !important; 
    text-shadow: 0px 0px #f5f9f711; 
    text-align: center;
    width: 50%;
    color: #fff;
    font-weight: 300 !important;
  }
.action-botton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.action-botton2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.content-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px;
    font-family: Mulish;
    font-size:17px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    span{
        font-size:20px;
        color: #800080e6;
        font-weight: bold;
    }
  }
  .content-services{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff !important;
  }
  .content-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
  .content-items{
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    box-shadow: -1px -1px 4px #ccc;
    border-radius: 4px;
    margin: 10px;
    padding: 15px;
    text-align: center;
  
    p{
      font-family: Mulish;
      font-size:20px;
      font-weight: 700;

      .fa-handshake-o{
        font-size:50px;
        color: #800080e6;
      }
      .fa-wallet{
        font-size:50px;
        color: #800080e6;
      }
      .fa-graduation-cap{
        font-size:50px;
        color: #800080e6;
      }
      .fa-leaf{
        font-size:50px;
        color: #800080e6;
      }
    }
  
    .items-text {
      font-family: Mulish;
      font-size:14px;
      font-weight: 500;
      text-align: center;
    }
  
  }
  .overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #33aacb14;
  }
  .about{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    background-size: cover;
    margin-top: 0px;
    height: auto;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg2.jpg) no-repeat fixed center;
    background-size: cover;

    h4{
        font-family: Mulish;
        font-size: 17px;
        font-weight: bold;
        text-align: justify;
    }
    p{
        font-family: Mulish;
        font-size: 13px;
    }
  }
  .team{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    
    h4{
        margin-top: 15px;
    }

    .name{
        font-family: Mulish;
        font-size: 13px;
        font-weight: bold;
    }
    i{
        margin-top: -15px;
    }
  }
  .border{
    padding: 1.5rem;
    h4{
        font-size: 24px;
    }
    p{
        font-size: 18px;
    }
  }
  .bd{
    padding: 1.5rem;
  }
  .about-banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    width: 100%;
    padding: 0.6rem;
  }
  .img-banner{
   
    img{
        width: 90%;
    }
  }

  .contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 11px;
    background: #33aacb14;
    background-size: cover;
    margin-top: 0px;
    height: auto;

    .support-text{
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
        text-align: justify;
    }
    h4{
        font-family: Mulish;
        font-size: 17px;
        font-weight: 500;
        text-align: justify;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 550;
        color: #3ea4c9c9;
    }
    img{
        width: 100%;
    }
  }
  .border{
    border-left: solid 0px #ccc;
    padding: 1.5rem;
  }
  .bd{
    padding: 1.5rem;
  }
  .contact-banner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    font-family: Mulish;
    padding: 1.6rem;

    .form-control{
        font-size: 12px;
        font-family: Mulish;
        border: solid 0px;
    }
    .input-contact{
        margin-top: 4px;
        color: #fff;
    }
  }


  .text-banner{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Mulish;
    width: 50%;
    p{
        text-align: justify;
    }

    .our-vision{
        margin: 4px;
        font-family: Mulish;
        font-size: 13px;

        .company{
            font-weight: bold;
        }
        .mission{
            font-weight: bold;
        }
        .vision{
            font-weight: bold;
        }
        .impact{
            font-weight: bold;
        }
    }
  }


   .content-footer1 {
    display: flex;
    flex-direction: column;
    color: #fff;
    bottom: 0;
    background: #800080e6; 
  }

  .content-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding-top: 2.7rem;
    padding-left: 5%;
    padding-right: 5%;
  }
  .footer-img{
    display: flex;
    flex-direction: column;
    text-align: left
  }
  .content-footer-text{
    font-family: Mulish;
    font-size:12px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #000;
  }
  
  .contact-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    img{ width:20% }

    p{
        font-family: Mulish;
        font-size:12px;    
    }
    a{
        text-decoration: none;
        color: #fff;
    }
  }
  .contact-title{
    font-size: 18px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .services-title{
    font-size: 16px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .copyright{
    font-family: Mulish;
    font-size:13px;
    font-weight: 400;
    text-align: left;
    padding-left: 5%;
  }
  
  .input-control {
    height: 30px;
    font-size: 12px;
    font-family: Mulish;
}
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f9f711;
    display: table;
    transition: opacity .5s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #37ABC8;
        background: #f2f3f2;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}
.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #FFF;
    }

    display: none;
}
.top {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    display: block;
    z-index: 9999;
    background: #800080;
    color: #fff;
    border-radius: 45px;
    padding: 10px;
    transition: visibility 0.5s, opacity 0.5s;

    a{
        color: #fff;
    }
}
.hidden {
    visibility: hidden;
    opacity: 0;
}

.contact-support{
    position: fixed;
    bottom: 20px;
    right: 80px;
    z-index: 9999;
    background: #ccc;
    color: #fff;
    border-radius: 12px;
    transition: visibility 0.5s, opacity 0.5s;

    img{
        width: 60px;
    }
}
@media (min-width:320px) and (max-width: 720px) {

.top-header{
    font-size: 10px;
}
.haddress{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    font-size: 10px;
}
.core-values{
    display: flex;
    flex-direction: column;

    h3{
        font-family: Mulish;
        font-size: 15px;
        font-weight: 750;
        color: #00000096;
        text-align: center;
        margin-top: 10px;
    }
    span{
        font-family: Mulish;
        font-size: 11px;
        font-weight: 200;
        text-align: center;
    }
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menu-bar {
    display: flex; 
    flex-direction: column; 
    height:70px;
}

.menu-bar1{
    display:block;
    .fa-bars{
        font-size: 22px;
        cursor: pointer;
    }
 }
 .menu-bar1:hover{
    
        .submenu-bar1{
            display: flex;
            flex-direction: column;
            right: 0;
            font-family: Mulish;
            font-size: 13px;
            border-right: solid 1px rgba(0, 0, 0, 0.3);;
            background: #800080;
            position: fixed;
            z-index: 9998;
            //margin-top:-40px;
            width: 100%;
            height: auto;
                                  
            a{
                text-decoration: none;
                color: #fff;
            }
        }
        .sub-menu1{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            border-left: solid 1px #ccc;
            border-bottom: solid 1px #ccc;
            text-transform: capitalize;
            cursor: pointer;
            padding: 0.8rem;
            width: 100%;
        }
 }
 .sub-menu1:hover{
    background: #f6c1f6;
    color: #800080 !important;
 }

.guarantee{
    div{
        font-family: Mulish;
        font-size: 14px;
        font-weight: 450;
        color: #00000096;
        text-align: center;
    }
}
.card{
    margin-top: 2%;
    border: solid 0px;
    border-bottom: solid 1px #fff;
    background: #8000808f;
}

.card-link{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 14px; 
    font-weight: 450;
    font-family: Mulish;
    height: 20px;
    color: #000;
   
}
.card-header{
    border-bottom: solid 1px #fff;
}
.card-body{
    font-size: 13px; 
    font-family: Mulish;
    color: #fff;
}

.bg-task{
    display: flex;
    flex-direction: column;
    padding: 0rem;

    h2{
        font-size: 30px; 
        font-weight: 600;
        font-family: Mulish;

    }
    p{
        font-size: 12px; 
        font-weight: 400;
        font-family: Mulish;

    }
    img{
        width: 90% !important;
    }
    .btn{
        margin-bottom: 10px;
    }
    .task-img{
        display: none;
    }
}
.bg-style{
    display:flex;
    flex-direction: column;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg1.jpg) no-repeat fixed center;
    background-size: cover;

    img{
        width: 100%;
    }
    .platform{
        display:flex;
        flex-direction: column;
        width: 100%;
        padding: 0.6rem;

        h2{
            font-size: 30px; 
            font-weight: 100;
            font-family: Mulish;
        }
        span{
            font-size: 14px; 
            font-weight: 350;
            font-family: Mulish;
        }
    }
}

#dailytask{
    display: flex;
    flex-direction: column;  
    justify-content: center; 
    align-content: center;
    width: 100%;

    h2{
        font-family: Mulish;
        margin-top: -40px;
        z-index: 9666;
        color: #000000c0;
    }

    p{
        font-family: Mulish;
        font-size: 12px;
    }
    img{
        width: 120%;
    }
}

#dailytask .d-flex .flex-column .pt-0 .mt-0 {
    display: flex;
    flex-direction: column;
  
}
   
    .sidebar {
        width: auto;
        height: auto;
        position: fixed;
        z-index: 9998;
        transition: opacity .4s ease;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0px;
        margin-left: 10px;

        a{
            color: #37ABC8;
        }
        .close{
            margin-left: 20px;
        }
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}

   .logo{
     img{ width: 150%; }
    }
    .fa-bars {
        display: block;
        font-size: 30px;
        cursor: pointer;
        color: #800080e6;
    }
    .menu {
        display: none;
    }
    .banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 9.5rem;
        bottom: 0;
        font-family: Mulish;
        font-size: 18px;
        background:#800080e6;
        overflow: hidden;
        position: relative;
        backdrop-filter: blur(16px);
        background: url(../assets/bg.jpg) no-repeat fixed center;
        background-size: cover;
        
        h1{
            font-family: Mulish;
            font-weight: 700;
            font-size: 30px;
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px #000; 
        }
        h4{
            font-family: Mulish;
            font-weight: 400;
            font-size: 15px;
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px #000; 
        }
        .btn-style{
            font-family: Mulish;
            text-align: center;
        }
        .img-style{
            display: flex;
            flex-direction: row;
            justify-content: center;
            img{
                text-align: center;
                width: 250px;
            }
        }
        .img-style1{
            display: flex;
            flex-direction: row;
            justify-content: center;
            img{
                text-align: center;
                width: 250px;
            }
        }
    }
    .action-botton{
        margin-top: 50px;
    }
    .action-botton2{
        margin-top: 50px;
    }
    .content-services{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .content-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
      }
      .content-items{
        box-sizing: border-box;
        width: 100%;
      }
      
      .about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        bottom: 0;
        font-family: Mulish;
        font-size: 10px;
        background: #33aacb14;
        background-size: cover;
        margin-top: 0px;
        box-shadow: -1px -1px 4px #ccc;
        position: relative;
        backdrop-filter: blur(16px);
        background: url(../assets/bg2.jpg) no-repeat fixed center;
        background-size: cover;
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: bold;
            text-align: justify;
        }
        p{
            font-family: Mulish;
            font-size: 13px;
        }
      }
      .team{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        
        h4{
            margin-top: 15px;
        }
    
        .name{
            font-family: Mulish;
            font-size: 13px;
            font-weight: bold;
        }
        i{
            margin-top: -15px;
        }
      }
      .border{
        border-left: solid 0px #ccc;
        padding: 1.5rem;
      }
      .bd{
        padding: 1.5rem;
      }
      .about-banner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        width: 100%;
        padding: 0.6rem;
      }
      .img-banner{
       
        img{
            width: 100%;
        }
      }

      .contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        bottom: 0;
        font-family: Mulish;
        font-size: 11px;
        background: #33aacb14;
        background-size: cover;
        margin-top: 0px;
        height: auto;
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: 500;
            text-align: justify;
        }
        span{
            font-family: Mulish;
            font-size: 13px;
            font-weight: 800;
        }
        img{
            width: 100%;
        }
      }

      .content-footer {
        display: flex;
        flex-direction: column;
        padding-top: 2.7rem;
        bottom: 0;   
        background: #8000802b; 
        padding-left: 5%;
        padding-right: 5%;
      }
      .content-footer-text{
        font-family: Mulish;
        font-size:13px;
        font-weight: 500;
        text-align: center;
        word-wrap: break-word;
        width: 28em; 
        color: #000;
      }
      .social-media{
        display: flex;
        flex-direction: row;
        justify-content: center;
      
        p{
          font-size: 20px; 
        }
      }

    
      .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 241, 241, 0.5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #37ABC8;
            background: #f4f7f5;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .contact-info{
        display: flex;
        flex-direction: column;
    
        img{ width:20% }
    
        p{
            font-family: Mulish;
            font-size:13px;    
        }
      }
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: none;
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: block;
    }

}
</style>