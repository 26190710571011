import Vue from 'vue'
import VueRouter from 'vue-router'
import SignupPage from '../views/SignupPage.vue'
import ReferralSignup from '../views/ReferralSignup.vue'
import SigninPage from '../views/SigninPage.vue'
import Dashboard from '../views/DashboardPage.vue'
//import ItemsLayout from '../views/ItemsLayout.vue'
import HomeView from '../views/HomeView.vue'
import BankPage from '../views/BankPage.vue'
import BankBvnPage from '../views/BankBvnPage.vue'
import ShopPage from '../views/ShopPage.vue'
import BrandPage from '../views/BrandPage.vue'
import CategoryPage from '../views/CategoryPage.vue'
import SubCategoryPage from '../views/SubCategoryPage.vue'
import ProductPage from '../views/ProductPage.vue'
import EditProduct from '../views/EditProduct.vue'
import MyProduct from '../views/MyProductPage.vue'
import ShoppingPage from '../views/ShoppingPage.vue'
import ShoppingDashboard from '../views/ShoppingDashboard.vue'
import ProductDetailPage from '../views/ProductDetailPage.vue'
import CartPage from '../views/CartPage.vue'
import PaymentDashboard from '../views/PaymentDashboard.vue'
import CheckoutDashboard from '../views/CheckoutDashboard.vue'
import PaymentPage from '../views/PaymentPage.vue'
import SchedulePayment from '../views/SchedulePayment.vue'
import CheckoutPage from '../views/CheckoutPage.vue'
import ReferralsPage from '../views/ReferralsPage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import PackageSubscriptionPage from '../views/PackageSubscriptionPage.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import PaymentSuccessful from '../views/PaymentSuccessful.vue'
import MyAccountMobile from '../views/MyAccountMobile.vue'
import MyOrderMobile from '../views/MyOrderMobile.vue'
import ProfileMobile from '../views/ProfileMobile.vue'

// FORGOT PASSWORD
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/CreatePassword.vue'


// BUYERS
import MyOrders from '../views/MyOrders.vue'

// MY ACCOUNTS
import MyAccounts from '../views/MyAccounts.vue'

import OrdersPage from '../views/OrdersPage.vue'
import Commissions from '../views/Commissions.vue'
import MyCommissions from '../views/MyCommissions.vue'
import ShoppingSearchPage from '../views/ShoppingSearchPage.vue'

import HomePage from "../views/HomePage.vue"
import TermsConditions from "../views/TermsConditions.vue"
import PrivacyPolicy from "../views/PrivacyPolicy.vue"

Vue.use(VueRouter)

// parse jwt token
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function beforeRouteEnter (to, from, next) {
  try{
  if (localStorage.getItem('wertyuiojhdfghhdrtfyguh')) {
    const jwtPayload = parseJwt(localStorage.getItem('wertyuiojhdfghhdrtfyguh'));
    if (jwtPayload.exp < Date.now()/1000) {
        // token expired
        //deleteTokenFromLocalStorage();
        next("/login");
    }
    next();
  } else {
    next("/login");
  }
  }catch (err) {
    console.log(err);
  }
}

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },  
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  },  
  {
    path: '/register',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/ref-register/:referral',
    name: 'ReferralSignup',
    component: ReferralSignup
  },
  {
    path: '/login',
    name: 'SigninPage',
    component: SigninPage
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsConditions',
    component: TermsConditions
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/home',
        name: 'HomeView',
        beforeEnter : beforeRouteEnter,
        component: HomeView,
        meta: {
          secure:true,
        }
      },  
      
    ]
  },
  {
    path: '/shopping',
    name: 'ShoppingDashboard',
    component: ShoppingDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/shopping',
        name: 'ShoppingPage',
        component: ShoppingPage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/search',
        name: 'ShoppingSearchPage',
        component: ShoppingSearchPage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/item/:id/:title',
        name: 'ProductDetailPage',
        //beforeEnter : beforeRouteEnter,
        component: ProductDetailPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/cart',
        name: 'CartPage',
        //beforeEnter : beforeRouteEnter,
        component: CartPage,
        meta: {
          secure:true,
        }
      },    
      {
        path: '/myaccounts',
        name: 'MyAccounts',
        beforeEnter : beforeRouteEnter,
        component: MyAccounts,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/mobile-profile',
        name: 'ProfileMobile',
        beforeEnter : beforeRouteEnter,
        component: ProfileMobile,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/mobile-accounts',
        name: 'MyAccountMobile',
        beforeEnter : beforeRouteEnter,
        component: MyAccountMobile,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/mobile-orders',
        name: 'MyOrderMobile',
        beforeEnter : beforeRouteEnter,
        component: MyOrderMobile,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/bank',
        name: 'BankPage',
        beforeEnter : beforeRouteEnter,
        component: BankPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/add-bank-bvn',
        name: 'BankBvnPage',
        beforeEnter : beforeRouteEnter,
        component: BankBvnPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/profile',
        name: 'ProfilePage',
        beforeEnter : beforeRouteEnter,
        component: ProfilePage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/referrals',
        name: 'ReferralsPage',
        beforeEnter : beforeRouteEnter,
        component: ReferralsPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shop',
        name: 'ShopPage',
        beforeEnter : beforeRouteEnter,
        component: ShopPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/brand',
        name: 'BrandPage',
        beforeEnter : beforeRouteEnter,
        component: BrandPage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/category',
        name: 'CategoryPage',
        beforeEnter : beforeRouteEnter,
        component: CategoryPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/subcategory',
        name: 'SubCategoryPage',
        beforeEnter : beforeRouteEnter,
        component: SubCategoryPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/product',
        name: 'ProductPage',
        beforeEnter : beforeRouteEnter,
        component: ProductPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/myproduct',
        name: 'MyProduct',
        beforeEnter : beforeRouteEnter,
        component: MyProduct,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/edit-product/:productid',
        name: 'EditProduct',
        beforeEnter : beforeRouteEnter,
        component: EditProduct,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/myorders',
        name: 'MyOrders',
        beforeEnter : beforeRouteEnter,
        component: MyOrders,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/orders',
        name: 'OrdersPage',
        beforeEnter : beforeRouteEnter,
        component: OrdersPage,
        meta: {
          secure:true,
        }
      },   
      
      {
        path: '/commissions',
        name: 'Commissions',
        beforeEnter : beforeRouteEnter,
        component: Commissions,
        meta: {
          secure:true,
        }
      },  
      
      {
        path: '/mycommissions',
        name: 'MyCommissions',
        beforeEnter : beforeRouteEnter,
        component: MyCommissions,
        meta: {
          secure:true,
        }
      },  
    ]
  },

  {
    path: '/checkout/summary',
    name: 'CheckoutDashboard',
    component: CheckoutDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/checkout/summary',
        name: 'CheckoutPage',
        beforeEnter : beforeRouteEnter,
        component: CheckoutPage,
        meta: {
          secure:true,
        }
      },       
    ]
  },

  {
    path: '/checkout/payment/review',
    name: 'PaymentDashboard',
    component: PaymentDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/checkout/payment/review',
        name: 'PaymentPage',
        beforeEnter : beforeRouteEnter,
        component: PaymentPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/make-payment/:paymentid',
        name: 'SchedulePayment',
        beforeEnter : beforeRouteEnter,
        component: SchedulePayment,
        meta: {
          secure:true,
        }
      },    
      {
        path: '/payment',
        name: 'PackageSubscriptionPage',
        beforeEnter : beforeRouteEnter,
        component: PackageSubscriptionPage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/payment-successful',
        name: 'PaymentSuccessful',
        beforeEnter : beforeRouteEnter,
        component: PaymentSuccessful,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/verify-email/:email_id',
        name: 'VerifyEmail',
        beforeEnter : beforeRouteEnter,
        component: VerifyEmail,
        meta: {
          secure:true,
        }
      },     
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
